<template>
  <div class="container">
    <div class="toolbar">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="medium"
        v-on:click="showAddRoleDialog"
        >新增角色</el-button
      >
    </div>
    <el-table :data="tableData" border size="small" style="width: 100%">
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="rolecode" label="角色编号"> </el-table-column>
      <el-table-column prop="rolename" label="角色名"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button @click="viewDetails(scope.row)" type="text" size="small"
            >查看</el-button
          >
          <el-button @click="editDetails(scope.row)" type="text" size="small"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 30]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </div>
    <el-dialog
      append-to-body
      title="角色详情"
      :visible.sync="viewDetailDialog"
      width="450px"
    >
      <ViewRoleDetails :role-data="viewDetailObj"></ViewRoleDetails>
    </el-dialog>

    <el-dialog
      title="编辑角色"
      :visible.sync="editDetailDialog"
      width="30%"
    >
      <el-row :gutter="20">
        <div style="margin-bottom: 10px; color: #303133">角色名</div>
        <el-input
          v-model="editDetailObj.rolename"
          placeholder="请输入角色名"
        ></el-input>
      </el-row>
      <span slot="footer">
        <el-button
          plain
          type="success"
          size="medium"
          v-on:click="updateRoleName"
          >确认修改</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      append-to-body
      title="新增角色"
      :visible.sync="addRoleDialog"
      width="450px"
    >
      <el-row :gutter="20">
        <div style="margin-bottom: 10px; color: #303133">角色名</div>
        <el-input v-model="addRolename" placeholder="请输入角色名"></el-input>
      </el-row>
      <span slot="footer">
        <el-button plain type="success" size="medium" v-on:click="addRole"
          >确认添加</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api";
import ViewRoleDetails from "../../components/ViewRoleDetails";

export default {
  components: { ViewRoleDetails },
  data() {
    return {
      pageIndex: 1,
      pageSize: 15,
      totalData: 0,
      tableData: [],
      viewDetailDialog: false,
      viewDetailObj: null,
      addRoleDialog: false,
      addRolename: "",
      editDetailObj: "",
      editDetailDialog: false,
      loginData: null,
    };
  },
  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.initList();
  },
  methods: {
    initList() {
      api
        .getRoleList({
          categorycode: this.loginData.categorycode,
          userlevel: this.loginData.userlevel,
          rolename: "",
          iseffective: "",
          page: this.pageIndex,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.totalData = res.data.data.totalRecords;
            this.tableData = res.data.data.list;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    viewDetails(row) {
      this.viewDetailObj = row;
      this.viewDetailDialog = true;
      console.log(this.viewDetailObj);
    },
    editDetails(row) {
      this.editDetailObj = row;
      this.editDetailDialog = true;
      console.log(this.viewDetailObj);
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initList();
    },
    showAddRoleDialog() {
      this.addRoleDialog = true;
      this.addRolename = "";
    },
    addRole() {
      if (!this.addRolename) {
        this.$message({
          showClose: true,
          message: "请填写完整",
          type: "warning",
        });
        return;
      }
      api
        .insertOrUpdateRole({
          rolename: this.addRolename,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.$message({
              showClose: true,
              message: "添加成功",
              type: "success",
            });
            this.addRoleDialog = false;
            this.initList();
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    updateRoleName() {
      if (!this.editDetailObj.rolename) {
        this.$message({
          showClose: true,
          message: "请填写完整",
          type: "warning",
        });
        return;
      }
      api
        .insertOrUpdateRole({
          rolecode: this.editDetailObj.rolecode,
          rolename: this.editDetailObj.rolename,
        })
        .then((res) => {
          if (res.data.success === "1") {
            this.$message({
              showClose: true,
              message: "修改成功",
              type: "success",
            });
            this.editDetailDialog = false;
            this.initList();
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
  },
};
</script>

<style scoped>
.toolbar {
}
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>