<template>
    <div class="container">
        <el-row :gutter="20">
            <div class="label">
                角色编号
            </div>
            <div class="value">{{roleData.rolecode}}</div>
        </el-row>
        <el-row :gutter="20">
            <div class="label">
                角色名
            </div>
            <div class="value">{{roleData.rolename}}</div>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "ViewRoleDetails",
        props: {
            roleData: Object,
        },
        data(){
            return {

            }
        },
        created() {

        },
        methods:{

        }
    }
</script>

<style scoped>
    .container{

    }
    .label{
        line-height: 30px;
        color: #303133;
    }
    .value{
        line-height: 30px;
        color: #909399;
    }
</style>